import axios from "axios";

const state = {
    selectedPost:''
};
const mutations = {

    SET_FETCHED_POST(state, payload) {
        state.selectedPost = payload;
    },

    SET_POST_STATUS(state, payload) {
        state.selectedPost.status = payload.status;
    },
    RESET_SELECTED_POST(state, payload) {
        state.selectedPost = '';
    },
};
const actions = {
    ALL_POSTS({commit},payload) {
        return new Promise((resolve, reject) => {
            axios
                .get("posts",{params: payload.params})
                .then(response => {
                    // commit('SET_ALL_EVENTS',response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    CREATE_ALBUM_POST(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("posts/album", payload)
                .then(response => {
                    // context.commit("SET_CREATED_COLLECTION", response.data.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UPDATE_ALBUM_POST(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("posts/album/"+payload.id, payload.form)
                .then(response => {
                    // context.commit("SET_CREATED_COLLECTION", response.data.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    CREATE_VIDEO_POST(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("posts/video", payload)
                .then(response => {
                    // context.commit("SET_CREATED_COLLECTION", response.data.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UPDATE_VIDEO_POST(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("posts/video/"+payload.id, payload.form)
                .then(response => {
                    // context.commit("SET_CREATED_COLLECTION", response.data.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UPDATE_POST_IMAGES(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("post-images/"+payload.id, payload.formData)
                .then(response => {
                    // context.commit("SET_CREATED_COLLECTION", response.data.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    UPDATE_POST_STATUS(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("post-status/"+payload.id, payload)
                .then(response => {
                    context.commit("SET_POST_STATUS", payload);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    FETCH_POST(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .get("posts/"+payload.id)
                .then(response => {
                    context.commit("SET_FETCHED_POST", response.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    FETCH_POST_REPORTS(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .get("posts/"+payload.post_id+'/reports',payload.params)
                .then(response => {
                    // context.commit("SET_FETCHED_POST", response.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    FETCH_POST_COMMENTS(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .get("posts/"+payload.post_id+'/comments',payload.params)
                .then(response => {
                    // context.commit("SET_FETCHED_POST", response.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

};
const getters = {
    selectedPost: state => state.selectedPost,
};
export default {
    state,
    mutations,
    getters,
    actions
};
