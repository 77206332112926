import axios from "axios";

const actions = {
    ALL_USERS(context,payload) {
        return new Promise((resolve, reject) => {
            axios
                .get("users",payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    CREATE_USER(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("users", payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UPDATE_USER(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("users/" + payload.id, payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DELETE_USER(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .delete("users/" + payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UPDATE_USER_STATUS(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .delete("users/update-status/" + payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

};

export default {
    actions
};
