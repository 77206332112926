import axios from "axios";

const state = {
    allCategories: [],
    allUsers: [],
    allOrganizers: [],
    selectedEvent:''
};
const mutations = {
    SET_ALL_CATEGORY(state, payload) {
        state.allCategories = payload.results;
    },
    SET_ALL_ADMIN_USERS(state, payload) {
        state.allUsers = payload.results;
    },
    SET_ALL_ORGANIZERS(state, payload) {
        state.allOrganizers = payload.results;
    },

    SET_FETCHED_EVENT(state, payload) {
        state.selectedEvent = payload;
        state.selectedEvent.resources = state.selectedEvent.resources.sort(function(a,b){
            return a.position-b.position ;
        });
    },

    SET_CREATED_TICKET(state, payload) {
        let scheduleIndex = state.selectedEvent.schedules.findIndex(x=>x._id == payload.schedule_id)
        if (scheduleIndex > -1)
        state.selectedEvent.schedules[scheduleIndex].tickets = payload.tickets;
    },
    DELETE_EVENT_TICKET(state, payload) {
        let scheduleIndex = state.selectedEvent.schedules.findIndex(x=>x._id == payload.schedule_id)
        if (scheduleIndex > -1){
            let ticketIndex = state.selectedEvent.schedules[scheduleIndex].tickets.findIndex(x=>x._id == payload.ticket_id)
            if (ticketIndex > -1){
                state.selectedEvent.schedules[scheduleIndex].tickets.splice(ticketIndex,1)
            }
        }
    },

    SET_CREATED_SCHEDULE(state, payload) {
        state.selectedEvent.schedules.push(payload.schedule);
    },
    SET_UPDATED_SCHEDULE(state, payload) {
        let scheduleIndex = state.selectedEvent.schedules.findIndex(x=>x._id == payload.schedule._id)
        if (scheduleIndex > -1)
            state.selectedEvent.schedules.splice(scheduleIndex,1,payload.schedule)
    },

    SET_DELETED_SCHEDULE(state, payload) {
        let scheduleIndex = state.selectedEvent.schedules.findIndex(x=>x._id == payload.schedule_id)
        if (scheduleIndex > -1){
                state.selectedEvent.schedules.splice(scheduleIndex,1)
        }
    },
    SET_EVENT_STATUS(state, payload) {
        state.selectedEvent.status = payload.status;
    },
    RESET_SELECTED_EVENT(state, payload) {
        state.selectedEvent = '';
    },
    DELETE_EVENT_RESOURCE(state, index) {
        state.selectedEvent.resources.splice(index,1)

    },
    CHANGE_DATE_TYPE(state, type) {
        state.selectedEvent.dateType=type

    }
};
const actions = {
    FETCH_ALL_COLLECTIONS({commit}) {
        return new Promise((resolve, reject) => {
            axios
                .get("collections?all="+true)
                .then(response => {
                    commit('SET_ALL_CATEGORY',response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    FETCH_ALL_ADMIN_USERS({commit}) {
        return new Promise((resolve, reject) => {
            axios
                .get("admin-users?all="+true)
                .then(response => {
                    commit('SET_ALL_ADMIN_USERS',response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    FETCH_ALL_ORGANIZERS({commit}) {
        return new Promise((resolve, reject) => {
            axios
                .get("organizers?all="+true)
                .then(response => {
                    commit('SET_ALL_ORGANIZERS',response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    ALL_EVENTS({commit},payload) {
        return new Promise((resolve, reject) => {
            axios
                .get("events",{params: payload.params})
                .then(response => {
                    // commit('SET_ALL_EVENTS',response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    CREATE_EVENT(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("events", payload)
                .then(response => {
                    // context.commit("SET_CREATED_COLLECTION", response.data.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UPDATE_EVENT(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("events/"+payload.id, payload.form)
                .then(response => {
                    // context.commit("SET_CREATED_COLLECTION", response.data.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UPDATE_EVENT_IMAGES(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("event-images/"+payload.id, payload.formData)
                .then(response => {
                    // context.commit("SET_CREATED_COLLECTION", response.data.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UPDATE_EVENT_DESCRIPTION(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("event-description/"+payload.id, payload.form)
                .then(response => {
                    // context.commit("SET_CREATED_COLLECTION", response.data.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UPDATE_EVENT_STATUS(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("event-status/"+payload.id, payload)
                .then(response => {
                    context.commit("SET_EVENT_STATUS", payload);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    FETCH_EVENT(context, payload) {
        // context.commit("RESET_SELECTED_EVENT");
        return new Promise((resolve, reject) => {
            axios
                .get("events/"+payload.id)
                .then(response => {
                    context.commit("SET_FETCHED_EVENT", response.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    CREATE_TICKET(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("events/"+payload.event_id+"/schedules/"+payload.schedule_id+"/tickets", payload.form)
                .then(response => {
                    context.commit("SET_CREATED_TICKET", {tickets: response.data.tickets, schedule_id : payload.schedule_id});
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UPDATE_TICKET(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("events/"+payload.event_id+"/schedules/"+payload.schedule_id+"/tickets/"+payload.form._id, payload.form)
                .then(response => {
                    context.commit("SET_CREATED_TICKET", {tickets: response.data.tickets, schedule_id : payload.schedule_id});
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DELETE_TICKET(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .delete("events/"+payload.event_id+"/schedules/"+payload.schedule_id+"/tickets/" + payload.id)
                .then(response => {
                    context.commit("DELETE_EVENT_TICKET", {schedule_id : payload.schedule_id, ticket_id : payload.id});
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    CREATE_SCHEDULE(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("event/"+payload.event_id+"/schedules", payload.form)
                .then(response => {
                    context.commit("SET_CREATED_SCHEDULE", response.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UPDATE_SCHEDULE(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("event/"+payload.event_id+"/schedules/"+payload.form._id, payload.form)
                .then(response => {
                    context.commit("SET_UPDATED_SCHEDULE", response.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DELETE_SCHEDULE(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .delete("event/"+payload.event_id+"/schedules/" + payload.id)
                .then(response => {
                    context.commit("SET_DELETED_SCHEDULE", {schedule_id: payload.id});
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

};
const getters = {
    getAllCategories: state => state.allCategories,
    getAllAdminUsers: state => state.allUsers,
    getAllOrganizers: state => state.allOrganizers,
    selectedEvent: state => state.selectedEvent,
};
export default {
    state,
    mutations,
    getters,
    actions
};
