import Vue from "vue";

Vue.filter('capitalizeFirstLetter',function(value){
   if (value){
       return value.charAt(0).toUpperCase() + value.slice(1);
   }
});

Vue.filter('getNameForAvatar',function(value){
   if (value){
       let split=value.split(' ')
       if (split.length>1)
       return split[0].charAt(0).toUpperCase() + split[1].charAt(0).toUpperCase();
       else return value.charAt(0).toUpperCase()
   }
   else return 'X'
});
Vue.filter('filterUniqueUsers',function(value){
   if (value.length){
      return value.filter(onlyUnique)
   }
   else return []
});
function onlyUnique(value, index, self) {
    let ind=self.findIndex(x=>x.user._id==value.user._id)
    return ind==index;
}
Vue.filter('countTickets',function(event){
    if (event){
        let total=0;
         event.schedules.forEach((item)=>{
            total+=item.tickets.length
        });
         return total
    }
});