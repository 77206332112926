import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/store";
import Description from '../views/event/Description'
Vue.use(VueRouter)

let routes = [
	{
		// will match everything
		path: '*',
		component: () => import('../views/404.vue'),
	},
	{
		path: '/',
		name: 'Home',
		redirect: '/dashboard',
	},

	{
		path: '/dashboard',
		name: 'Dashboard',
		layout: "dashboard",
		meta: {
			requiresAuth: true
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
	},
	{
		path: '/collections',
		name: 'Collections',
		layout: "dashboard",
		meta: {
			requiresAuth: true
		},
		component: () => import('../views/collection/List.vue'),
	},
    {
		path: '/events',
		name: 'Events',
		layout: "dashboard",
		meta: {
			requiresAuth: true
		},
		component: () => import('../views/event/List.vue'),
	},
    {
		path: '/events/create',
		name: 'Create Event',
		layout: "dashboard",
		meta: {
			requiresAuth: true
		},
		component: () => import('../views/event/Form.vue'),
	},
	{
		path: '/events/:id/edit',
		layout: "dashboard",
		meta: {
			requiresAuth: true
		},
		component: () => import('../views/event/EditTab.vue'),
		children:[{
			path: 'basic-info',
			name: 'Edit Event | Basic Info',
			component: () => import('../views/event/Form'),

		},
			{
			path: 'images',
				name: 'Edit Event | Images',
				component: () => import('../views/event/Images'),

		},
		{
			path: 'descriptions',
				name: 'Edit Event | Descriptions',
				component: Description,

		},
			{
			path: 'tickets',
				name: 'Edit Event | Tickets',
				component: () => import('../views/event/Ticket'),

		},{
			path: 'stats',
				name: 'Edit Event | Stats',
				component: () => import('../views/event/Stats'),

		},{
			path: 'schedules',
				name: 'Edit Event | Schedules',
				component: () => import('../views/event/Schedules'),

		}
		,{
			path: 'publish',
				name: 'Edit Event | Publish/Summary',
				component: () => import('../views/event/Publish'),

		}]
	},
	{
		path: '/forums',
		name: 'Forum Feeds',
		layout: "dashboard",
		meta: {
			requiresAuth: true
		},
		component: () => import('../views/forum/List'),
	},
	{
		path: '/forums/:id',
		name: 'Forum Discussion',
		layout: "dashboard",
		meta: {
			requiresAuth: true
		},
		component: () => import('../views/forum/Discussion'),
	},
	{
		path: '/albums',
		name: 'Image Albums',
		layout: "dashboard",
		meta: {
			requiresAuth: true
		},
		component: () => import('../views/album/List'),
	},
	{
		path: '/albums/create',
		name: 'Create Image Album',
		layout: "dashboard",
		meta: {
			requiresAuth: true
		},
		component: () => import('../views/album/Form.vue'),
	},
	{
		path: '/albums/:id/edit',
		layout: "dashboard",
		meta: {
			requiresAuth: true
		},
		component: () => import('../views/album/EditTab.vue'),
		children:[{
			path: 'basic-info',
			name: 'Edit Album | Basic Info',
			component: () => import('../views/album/Form'),

		},
			{
				path: 'images',
				name: 'Edit Album | Images',
				component: () => import('../views/album/Images'),

			},
			{
				path: 'summary',
				name: 'Edit Album | Summary',
				component: () => import('../views/album/PublishAndSummary'),

			},
			{
				path: 'comments',
				name: 'Edit Album | Comments',
				component: () => import('../views/album/Comments'),

			},
			{
				path: 'reports',
				name: 'Edit Album | Reports',
				component: () => import('../views/album/Reports'),

			},
			]
	},
	{
		path: '/video-clips',
		name: 'Video Clips',
		layout: "dashboard",
		meta: {
			requiresAuth: true
		},
		component: () => import('../views/video-event/List'),
	},
	{
		path: '/video-clips/create',
		name: 'Create Video Clip',
		layout: "dashboard",
		meta: {
			requiresAuth: true
		},
		component: () => import('../views/video-event/Form.vue'),
	},
	{
		path: '/video-clips/:id/edit',
		layout: "dashboard",
		meta: {
			requiresAuth: true
		},
		component: () => import('../views/video-event/EditTab.vue'),
		children:[{
			path: 'basic-info',
			name: 'Edit Video | Basic Info',
			component: () => import('../views/video-event/Form'),

		},

			{
				path: 'summary',
				name: 'Edit Video | Publish/Summary',
				component: () => import('../views/video-event/PublishAndSummary'),

			},
			{
				path: 'comments',
				name: 'Edit Video | Comments',
				component: () => import('../views/video-event/Comments'),

			},
			{
				path: 'reports',
				name: 'Edit Video | Reports',
				component: () => import('../views/video-event/Reports'),

			},
		]
	},
	{
		path: '/organizers',
		name: 'Organizers',
		layout: "dashboard",
		meta: {
			requiresAuth: true
		},
		component: () => import('../views/organizer/List.vue'),
	},
	{
		path: '/ticket-sellers',
		name: 'Ticket Sellers',
		layout: "dashboard",
		meta: {
			requiresAuth: true
		},
		component: () => import('../views/seller/List.vue'),
	},
	{
		path: '/report-reasons',
		name: 'Report Reasons',
		layout: "dashboard",
		meta: {
			requiresAuth: true
		},
		component: () => import('../views/report-reason/List.vue'),
	},
	{
		path: '/app-users',
		name: 'Mobile App Users',
		layout: "dashboard",
		meta: {
			requiresAuth: true
		},
		component: () => import('../views/viewer/List.vue'),
	},
	{
		path: '/app-users/view/:id',
		name: 'View User',
		layout: "dashboard",
		meta: {
			requiresAuth: true
		},
		component: () => import('../views/viewer/Profile.vue'),
	},
	{
		path: '/admin-users',
		name: 'Admin Users',
		layout: "dashboard",
		meta: {
			requiresAuth: true
		},
		component: () => import('../views/admin-user/List.vue'),
	},
	{
		path: '/layout',
		name: 'Layout',
		layout: "dashboard",
		component: () => import('../views/Layout.vue'),
	},
	// {
	// 	path: '/tables',
	// 	name: 'Tables',
	// 	layout: "dashboard",
	// 	component: () => import('../views/Tables.vue'),
	// },
	// {
	// 	path: '/billing',
	// 	name: 'Billing',
	// 	layout: "dashboard",
	// 	component: () => import('../views/Billing.vue'),
	// },

	{
		path: '/Profile',
		name: 'Profile',
		layout: "dashboard",
		meta: {
			requiresAuth: true
		},
		component: () => import('../views/profile/Profile.vue'),
	},
	{
		path: '/sign-in',
		name: 'Sign-In',
		meta: {isVisitor:true},
		component: () => import('../views/Sign-In.vue'),
	},
	// {
	// 	path: '/sign-up',
	// 	name: 'Sign-Up',
	// 	meta: {
	// 		layoutClass: 'layout-sign-up',
	// 	},
	// 	component: () => import('../views/Sign-Up.vue'),
	// },
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;

	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		if ( to.hash ) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
})

router.beforeEach((to, from, next) => {
	document.title = to.name;
	if (to.matched.some(record => record.meta.isVisitor)) {
		if (store.getters.isLoggedIn) {
				next({ name: "Dashboard" });
				return;
		}
		next();
	} else if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!store.getters.isLoggedIn) {
			next({ name: "Sign-In" });
			return;
		}
		next();
	} else {
		next();
	}
})

export default router
