import axios from "axios";

const actions = {
    ALL_REASONS(context,payload) {
        return new Promise((resolve, reject) => {
            axios
                .get("reasons",payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    CREATE_REASON(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("reasons", payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UPDATE_REASON(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("reasons/" + payload.id, payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DELETE_REASON(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .delete("reasons/" + payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

};

export default {
    actions
};
