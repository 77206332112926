import axios from "axios";

const actions = {
    ALL_FORUMS(context,payload) {
        return new Promise((resolve, reject) => {
            axios
                .get("forum-feeds",payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    CREATE_FORUM(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("forum-feeds", payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    FETCH_FORUM(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .get("forum-feeds/"+payload.id)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    POST_REPLY(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("forum-feeds/"+payload.id+"/reply",payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UPDATE_REPLY(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("forum-feeds/"+payload.discussion_id+"/reply/"+payload.id,payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DELETE_REPLY(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .delete("forum-feeds/"+payload.discussion_id+"/reply/"+payload.id)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UPDATE_FORUM(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("forum-feeds/" + payload.id, payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DELETE_FORUM(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .delete("forum-feeds/" + payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

};

export default {
    actions
};
