import axios from "axios";

const actions = {
    GET_USER_PROFILE(context,payload) {
        return new Promise((resolve, reject) => {
            axios
                .get("profile")
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UPDATE_USER_PROFILE(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("profile", payload.formData)
                .then(response => {
                    const user = response.data.data
                    const user_detail = { email: user.email, name: user.name, phone: user.phone, image_url:user.imageUrl }
                    const user_data=JSON.stringify(user_detail)
                    localStorage.setItem('user', user_data)
                    context.commit('setUser',user_detail)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

};

export default {
    actions
};
