import axios from "axios";
const state = {
	token: localStorage.getItem('token'),
	user: localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):'',
	// role: localStorage.getItem('role'),

}

const mutations = {
	setToken(state, payload) {
		state.token = payload.token;
		// state.role = localStorage.getItem('role');
		state.user = payload.user;
	},
	setLogOut(state) {
		state.token = ''
		state.user = ''
	},
	setUser(state,payload) {
		state.user = payload
	},
}

const actions = {
	async loginAttempt({commit}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('login', payload)
				.then(response => {
					const data = response.data;
						const token = 'Bearer '+data.token
						localStorage.setItem('token', token)
					const user=JSON.stringify(data.user)
						localStorage.setItem('user', user)
						commit('setToken', data)
					resolve(response)
				}).catch(error => {
				reject(error)
			})
		})

	},

	logOut({commit}) {
		return new Promise((resolve, reject) => {
			axios.post('logout')
				.then(response => {
					localStorage.removeItem('token')
					localStorage.removeItem('user')
					commit('setLogOut')
					resolve(response)
				}).catch(error => {
				reject(error)
			})
			})
	}
}
const getters = {
	isLoggedIn: state => !!state.token,
	// role: state => state.role,
	user: state => state.user,
	// companyDetails: state => state.company,
	// companyId:state=>state.company_id
}

export default {
	state,
	mutations,
	actions,
	getters
}
