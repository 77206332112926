import axios from "axios";

const actions = {
    GET_DASHBOARD(context,payload) {
        return new Promise((resolve, reject) => {
            axios
                .get("dashboard",payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

};

export default {
    actions
};
