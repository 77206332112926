import axios from "axios";

const actions = {
    ALL_COLLECTIONS(context,payload) {
        return new Promise((resolve, reject) => {
            axios
                .get("collections",payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    CREATE_COLLECTION(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("collections", payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UPDATE_COLLECTION(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("collections/" + payload.id, payload.formData)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DELETE_COLLECTION(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .delete("collections/" + payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

};

export default {
    actions
};
