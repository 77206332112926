import axios from "axios";
const state = {
    allSellers: [],
};
const mutations = {
    SET_ALL_TICKET_SELLERS(state, payload) {
        state.allSellers = payload.results;
    },
}
const actions = {
    ALL_TICKET_SELLERS(context,payload) {
        return new Promise((resolve, reject) => {
            axios
                .get("sellers",payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    CREATE_TICKET_SELLER(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("sellers", payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UPDATE_TICKET_SELLER(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("sellers/" + payload.id, payload.formData)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DELETE_TICKET_SELLER(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .delete("sellers/" + payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    FETCH_ALL_TICKET_SELLERS({commit}) {
        return new Promise((resolve, reject) => {
            axios
                .get("sellers?all="+true)
                .then(response => {
                    commit('SET_ALL_TICKET_SELLERS',response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};
const getters = {
    getAllSellers: state => state.allSellers,
};
export default {
    state,
    mutations,
    getters,
    actions
};
