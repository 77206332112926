import axios from "axios";

const actions = {
    ALL_ORGANIZERS(context,payload) {
        return new Promise((resolve, reject) => {
            axios
                .get("organizers",payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    CREATE_ORGANIZER(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("organizers", payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UPDATE_ORGANIZER(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("organizers/" + payload.id, payload.formData)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DELETE_ORGANIZER(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .delete("organizers/" + payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

};

export default {
    actions
};
