<template>
  <div>
    <!-- Authors Table -->
    <a-row :gutter="24" type="flex">

      <!-- Authors Table Column -->
      <a-col :span="24" class="mb-24">

        <a-card :bordered="true" class="header-solid h-full" v-if="!loading" :loading="loading">
          <a-form layout='vertical'  @submit="handleSubmit">
            <a-form-item>
              <div class="ant-page-header-heading-title">Description</div>
            </a-form-item>
            <a-form-item>
              <quill-editor
                  id="editor"
                  :options="editorOption"
                  style="height: 260px; margin-bottom: 44px"
                 v-model="data"
              ></quill-editor>
<!--              <Vueditor v-model="data" style="height: 350px;"></Vueditor>-->

              <!--              <tinymce ref="tiny_mce" :other_options="{-->
<!--         height: 300,-->
<!--         }" id="d1" v-model="data"></tinymce>-->
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 24 }" :style="{ textAlign: 'right' }">
              <a-space size="small">
                <a-button type="primary" html-type="submit" :loading="confirmLoading">
                  Update
                </a-button>
                <router-link :to="{name:'Events'}">
                <a-button type="danger" :loading="confirmLoading">
                  Cancel
                </a-button>
                </router-link>
              </a-space>
            </a-form-item>
          </a-form>
        </a-card>
        <a-card :bordered="true" class="header-solid h-full" style="min-height: 400px" v-else>
          <a-row :gutter="[24, 24]">
            <a-col :span="24" >
              <a-skeleton active />
              <a-skeleton active />
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import Vue from 'vue'
// import tinymce from 'vue-tinymce-editor'
// import Vueditor from 'vueditor'

// import 'vueditor/dist/style/vueditor.min.css'
import "quill/dist/quill.snow.css";

import { quillEditor, Quill } from "vue-quill-editor";
// const sizeStyle = Quill.import("attributors/style/size");
// your config here
// let config = {
//   toolbar: [
//     'removeFormat', 'undo', '|', 'elements', 'fontName', 'fontSize', 'foreColor', 'backColor'
//   ],
//   fontName: [
//     {val: 'arial black'},
//     {val: 'times new roman'},
//     {val: 'Courier New'}
//   ],
//   fontSize: ['12px', '14px', '16px', '18px', '0.8rem', '1.0rem', '1.2rem', '1.5rem', '2.0rem'],
// };

// Vue.use(quillEditor, config);
export default {

  data() {
    return {
      loading:false,
      confirmLoading:false,
      data: '',
      editorOption: {
        modules: {
          toolbar: [
            [{ font: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [
              {
                size: [
                  false,
                  "10px",
                  "12px",
                  "14px",
                  "16px",
                  "18px",
                  "20px",
                  "24px",
                  "30px",
                  "32px",
                  "36px",
                ],
              },
            ],
            [{ color: [] }],
            [{ background: [] }],

            ["bold", "italic", "underline", "strike", "link"],
            // Bold, italic, underline, strikethrough
            // Title, the form of key-value pairs; 1, 2 represents the font size
            [{ list: "ordered" }, { list: "bullet" }],

            [{ indent: "-1" }, { indent: "+1" }],
            // indent

            // font color, font background color
            // font
            [{ align: [] }],
            // alignment

            ["clean"],
            // Clear font style
          ],
        },
        placeholder: "Description Here",
      },

    };
  },
  components:{quillEditor},
  computed: {
    selectedItem(){
      return this.$store.getters.selectedEvent
    }
  },
  methods:{
    handleSubmit(e) {
      e.preventDefault();

      // this.form.validateFields((err, values) => {
      //   if (!err) {
      if (!this.data){
        this.$message.error('Description can not be empty');
        return
      }
          this.confirmLoading = true
          this.$store.dispatch("UPDATE_EVENT_DESCRIPTION", {form:{description:this.data},id:this.selectedItem._id})
              .then((res) => {
                this.$message.success(res.data.message);
                this.$emit('changeTab','3')
              })
              .catch((err) => {
                // this.$message.error(err.response.data.message);
              })
              .finally(() => {
                this.confirmLoading = false
              });
      //   }
      // });
    },
    eventInfo(){
      this.loading=true
      this.$store.dispatch("FETCH_EVENT",{id:this.$route.params.id})
          .then((res)=>{

            this.data=this.$store.getters.selectedEvent.description

          }).finally(()=>{
        this.loading=false
      })
    },
  },
  mounted() {
    this.eventInfo()
  },
  // components:{tinymce},
  beforeCreate() {
    console.log(this.$route.name)
    // debugger
    // setTimeout(()=>{
    //   Vue.component('tinymce', tinymce)

    // },100)
  },
// destroyed() {
//   this.$refs.tiny_mce.editor.destroy()
// }

};
</script>
<style>
 .ql-toolbar.ql-snow {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

 .ql-container.ql-snow {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
</style>